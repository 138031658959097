import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

async function onPropertyListChange(
  projectId,
  type,
  ordering = "",
  pagination = "",
  filtering = ""
) {
  const boolPropType = {};
  boolPropType["id"] = type
    ? { _eq: type }
    : { _nin: ["PARKING", "WAREHOUSE"] };

  const response = graphqlClient.subscribe({
    query: gql`
        subscription properties($projectId: uuid!, $boolPropType: property_type_bool_exp) {
          property(where: {
            project_id: {_eq: $projectId},
            property_type: $boolPropType
            _or:[
              {deleted: { _is_null: true }},
              {deleted: { _eq: false }}
            ],
            ${filtering}
          }
          ${ordering} ${pagination}
          ) {
              id
              property_type {
                id
                name
              }
          status
              unit_number
              level
              total_area
          living_area
          construction_area
              rooms
              bathrooms
              price
          number
          unit_type
          unit_type_id
          balcony_area
            garden_area
            terrace_area
          project {
              id
              name
              country_currency {
                currency {
                  id
                  name
                  code
                  symbol
                }
              }
            }
          property_attribute_values {
            id
            value
            property_attribute
          }
          property_images {
            id
            img_url
            title
            type
            property_image_gallery
          }
          project_unit_type {
            id
            type
            img_url
            project_unit_type_images {
              id
              image_url
            }
          }
          property_status {
            id
            name
          }
          total_area
          assigned_properties(where: { _or:[
            {deleted: {_eq: false}},
            {deleted: {_is_null: true}}
          ]}) {
              property {
                id
                unit_number
                property_type_id
                price
              }
            }
          assignedPropertiesByPropertyAssignedId(where: { _or:[
            {deleted: {_eq: false}},
            {deleted: {_is_null: true}}
          ]}) {
            propertyByPropertyId {
                id
                unit_number
                status
              }
            }
          }
        }
      `,
    variables: {
      projectId,
      boolPropType,
    },
  });

  return response;
}

async function onPropertyAssignedChange(
  projectId,
  propertyType = "PARKING",
  ordering = "",
  pagination = "",
  filtering = ""
) {
  const response = graphqlClient.subscribe({
    query: gql`
    subscription onPropertyAssignedChange{
      assigned_property (
        where: {
          _or:[
            {deleted: {_eq: false}},
            {deleted: {_is_null: true}}
          ],
          _and:{
            property:{
              project_id:{
                _eq: "${projectId}"
              },
              property_type: {
                id:{
                  _eq: "${propertyType}"
                }
              }
            },
            propertyByPropertyId: {
              project_id: {
                _eq: "${projectId}"
              }
            },
          }
          ${filtering}
        },
        ${ordering} ${pagination}
      ){
        id
        property_assigned_id
        deleted
        property {
          id
          unit_number
          price
          status
          property_type {
            id
            name
          }
          property_status {
            id
            name
          }
        }
        property_id
        propertyByPropertyId {
          id
          unit_number
          price
          status
          property_type {
            id
            name
          }
          property_status {
            id
            name
          }
        }
      }
    }
    `,
  });

  return response;
}

export default {
  onPropertyListChange,
  onPropertyAssignedChange,
};
