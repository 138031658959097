<template>
  <div>
    <div class="mb-8">
      <vx-card
        :title="`🚪🚪🚪 Importador masivo de unidades para ${project.name}.`"
        title-color="black"
      >
        <p class="mb-1">
          Si desea un archivo base con las columnas necesarias, puede descargar
          el archivo pulsando el botón de archivo base.
        </p>
        <p>
          🏸 Verfique el archivo base, en el cual encontrara un ejemplo<br />
          de como ingresar sus propiedades de forma fácil.
        </p>

        <vs-button
          color="primary"
          icon-pack="fas"
          icon="fa-file-excel"
          class="m-2 mt-4"
          type="flat"
          href="https://flattlo-app.s3.amazonaws.com/base-download-files/Propiedades_Flattlo.xlsx"
          >&nbsp; Descargar archivo base</vs-button
        >
      </vx-card>
      <br />

      <ExcelBatchImporter :onSuccess="loadDataInTable" />
    </div>
    <vx-card
      v-if="tableData.length && header.length"
      title="📁📃 Carga correcta."
      title-color="black"
    >
      <p>
        Se han encontrado <strong>{{ tableData.length }}</strong> registros.
      </p>
      <vs-row class="flex">
        <vs-button
          @click="uploadProperties()"
          color="success"
          class="mt-4 m-1"
          type="filled"
          icon-pack="feather"
          icon="icon-upload-cloud"
          >Cargar Unidades</vs-button
        >
        <vs-button
          @click="tableData = []"
          class="mt-4 m-1"
          color="danger"
          icon-pack="feather"
          icon="icon-x"
        ></vs-button>
      </vs-row>
      <br />
      <vs-progress
        v-if="statusBar > 0"
        :height="12"
        :percent="statusBar"
        color="success"
      ></vs-progress>
      <p v-if="statusBar > 0">
        Importado correcto de <strong>{{ statusBar }}%</strong>
      </p>
    </vx-card>
    <br />
    <vx-card v-if="error">
      <vs-list>
        <vs-list-header
          title="Las siguientes filas del archivo contienen errores:"
          color="danger"
        ></vs-list-header>
        <vs-list-item
          v-for="e in dataWithErrors"
          :key="e.row"
          :title="e.row"
          :subtitle="`Error: ${e.message}`"
        ></vs-list-item>
      </vs-list>
    </vx-card>
    <br />

    <vs-alert :active="loadError" color="danger" icon="new_releases">
      <span
        >Ha ocurrido un error al procesar el archivo. Por favor intenta de
        nuevo.</span
      >
    </vs-alert>

    <vx-card v-if="tableData.length && header.length">
      <vs-table stripe :max-items="50" search :data="tableData">
        <template slot="header">
          <h4>{{ sheetName }}</h4>
        </template>

        <template slot="thead">
          <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{
            heading
          }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td
              :data="col"
              v-for="(col, index) in data[indextr]"
              :key="index"
            >
              {{ col }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import router from "../../../router";
import ExcelBatchImporter from "../../../components/ExcelBatchImporter.vue";
import { batch, propertyApi } from "../services";
import { unitTypeApi, api } from "../../projects/services";
import { capitalize } from "../../../helpers/capitalize";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  components: {
    ExcelBatchImporter,
  },
  data() {
    return {
      tableData: [],
      header: [],
      sheetName: "",
      statusBar: 0,
      propertyStatus: [],
      project: null,
      error: false,
      loadError: false,
      dataWithErrors: [],
      propertyTypes: [],
    };
  },
  async mounted() {
    const projectId = this.$store.state.project.currentProjectId;
    this.propertyStatus = await propertyApi.getPropertyStatus();
    this.propertyTypes = await propertyApi.getProperyTypes();
    this.project = await api.get(projectId);

    this.$store.state.project.breadcrumbs = [
      { pageName: 'Edición de proyectos', 
        route: '/proyectos' 
      },{
        pageName: `Propiedades en ${this.project.name}`,
        route: `/agregar-propiedades/proyecto/${projectId}`
      },{
        pageName: `Importador masivo`,
        route: `/importador-de-apartamentos`
      }
    ];
  },
  methods: {
    loadDataInTable({ results, header, meta }) {
      this.header = header;
      this.tableData = results;
      this.sheetName = meta.sheetName;
    },
    goToInventory() {
      setTimeout(() => {
        router.push({ path: "/seleccion-de-nivel/" });
      }, 2000);
    },
    async addUnitTypes(projectId, tableData){
      const unitTypesPromises = tableData.map(async (property) => {
          const unitType = property.tipo ? property.tipo.trim() : "";
          if(unitType){
            const getUnitType = await unitTypeApi.get(projectId, unitType);
            if(!getUnitType){
              return unitType;
            }
            return null;
          }
          return null;
        });

        const unitTypes = await Promise.all(unitTypesPromises);
        const filteredUniqueUnitTypes = [...new Set(unitTypes)].filter(val => val !== null);
        const addUnitTypes = filteredUniqueUnitTypes.map(async (type) => {
          await unitTypeApi.add(projectId, type);
        });

        await Promise.all(addUnitTypes);
    },
    async uploadProperties() {
      try {
        const projectId = this.$store.state.project.currentProjectId;
        // let properties = [];
        // let projectTypes = [];
        this.error = false;
        this.loadError = false;
        this.dataWithErrors = [];

        const batchQuery = await propertyApi.getBatchPropertyStatuses(
          this.tableData
        );
        const arrayBatchQuery = Object.entries(batchQuery);

        const itemsNoCompleted = arrayBatchQuery.filter((item) => {
          const [row, data] = item;
          if (data.length == 0) return [row, data];
        });

        if (itemsNoCompleted.length) {
          this.error = true;
          this.dataIsLoaded = false;
          this.dataWithErrors = itemsNoCompleted.map((item) => {
            return {
              row: item[0],
              message: "estado no encontrado.",
            };
          });

          return;
        }

        await this.addUnitTypes(projectId, this.tableData);

        const properties = this.tableData.map(async (property) => {
          const statusBarIncrement =
            Math.round((100 / arrayBatchQuery.length) * 100) / 100;

          const livingArea = property.area_habitable
            ? parseFloat(property.area_habitable)
            : 0;

          const balconyArea = property.area_balcon
            ? parseFloat(property.area_balcon)
            : 0;

          const gardenArea = property.area_jardin
            ? parseFloat(property.area_jardin)
            : 0;

          const terraceArea = property.area_terraza
            ? parseFloat(property.area_terraza)
            : 0;

          const constructionArea = property.area_construida
            ? parseFloat(property.area_construida)
            : 0;

          let totalArea = 0;

          if (!property.area_total) {
            totalArea =
              balconyArea + gardenArea + terraceArea + constructionArea;
          } else {
            totalArea = parseFloat(property.area_total);
          }

          const statusCapitalize = capitalize(
            property.estado.toLowerCase().trim()
          );

          const status = this.propertyStatus.find(
            (element) => element.name === statusCapitalize
          );

          let unitTypeId = null;
          const unitType = property.tipo ? property.tipo.trim() : "";
          if (unitType) {
            const getUnitType = await unitTypeApi.get(projectId, unitType);
            unitTypeId = getUnitType.id;
          }

          const propertyTypeId =
            this.propertyTypes.find(
              (i) => i.name === property.tipo_propiedad
            ) || null;

          try {
            await batch.importBatch(projectId, {
              type: propertyTypeId ? propertyTypeId.id : "APARTMENT",
              status: status ? status.id : "BLOCKED",
              unitNumber: property.numero.toString().trim(),
              level: property.nivel ? property.nivel.toString().trim() : "1",
              tower: property.torre ? property.torre.toString().trim() : null,
              view: property.vista,
              bedrooms: property.dormitorios ? parseInt(property.dormitorios) : 1,
              bathrooms: property.banos ? parseInt(property.banos) : 1,
              price: property.precio ? parseFloat(property.precio) : 0,
              unitType: unitTypeId,
              balcony: property.balcon.toUpperCase() == "SI" ? true : false,
              kitchenFurniture:
                property.muebles_de_cocina.toUpperCase() == "SI" ? true : false,
              closet: property.closets.toUpperCase() == "SI" ? true : false,
              laundry: property.lavanderia.toUpperCase() == "SI" ? true : false,
              garden: property.jardin.toUpperCase() == "SI" ? true : false,
              homeAppliances:
                property.electrodomesticos.toUpperCase() == "SI" ? true : false,
              livingArea: livingArea,
              terraceArea: terraceArea,
              balconyArea: balconyArea,
              gardenArea: gardenArea,
              constructionArea: constructionArea,
              totalArea: totalArea,
            });
          } catch (error) {
            console.error(`Error con la propiedad ${property.numero}`);
            console.error(error);
          }

          this.statusBar =
            Math.round((this.statusBar + statusBarIncrement) * 100) / 100;
        });

        await Promise.all(properties);
        
        this.$vs.notify({
          time: 6000,
          title: `Batch importado! 🏢🚀`,
          text: `Las propiedades fueron creadas correctamente.`,
          color: "success",
        });

        this.$router.push({
          name: "Agregar Propiedades",
          params: { projectId },
        });
      } catch (e) {
        sentryCaptureException(e, "property-importer");
        const error = e.toString();
        this.statusBar = 0;
        this.loadError = true;

        this.$vs.notify({
          time: 6000,
          title: `Error `,
          text: `Hubo un error cargando los apartamentos, por favor revisar el formato del archivo.\n${error}`,
          color: "danger",
        });
      }
    },
  },
};
</script>
