import propertyApi from "./property.api";

async function importBatch(projectId, property) {
  const response =
    property.type !== "PARKING" && property.type !== "WAREHOUSE"
      ? await propertyApi.createMainProperty(projectId, property)
      : await propertyApi.createExtraProperty(projectId, property);

  return response;
}

export default {
  importBatch,
};
