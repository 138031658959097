import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

async function add(properties) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation addAssignedProperty($object: assigned_property_insert_input!) {
        insert_assigned_property_one(object: $object) {
          id
          property {
            id
            unit_number
            unit_type
          }
          propertyByPropertyId {
            id
            unit_number
            unit_type
          }
        }
      }
    `,
    variables: {
      object: {
        property_id: properties.propertyId,
        property_assigned_id: properties.propertyAssignedId,
      },
    },
  });

  return response.data.insert_assigned_property_one;
}

async function getPropertiesByAssigned(assignedPropertyId) {
  const response = await graphqlClient.query({
    query: gql`
      {
        assigned_property(where: {
          property_assigned_id:{
            _eq: "${assignedPropertyId}"
          },
          deleted: {
            _eq: false   
          }
        }) {
          id
          property_id
          propertyByPropertyId {
            id
            unit_number
            property_type_id
            status
            property_status {
              id
              name
            }
          }
          property_assigned_id
          property {
            id
            unit_number
            property_type_id
            status
          }
        }
      }
    `,
  });
  return response.data.assigned_property;
}

async function getByPropertyAndAssigned(propertyId, propertyAssignedId) {
  const response = await graphqlClient.query({
    query: gql`
      {
        assigned_property (where:{
          property_id: {
            _eq: "${propertyId}"
          },
          property_assigned_id:{
            _eq: "${propertyAssignedId}"
          }
        }){
          id
          property_id
          propertyByPropertyId{
            id
            unit_number
            property_type_id
          }
          property_assigned_id
          property {
            id
            unit_number
            property_type_id
          }
        }
      }
    `,
  });
  return response.data.assigned_property;
}

export default {
  add,
  getPropertiesByAssigned,
  getByPropertyAndAssigned,
};
